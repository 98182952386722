export function useSelectedCompany() {
    const authStore = useAuthStore()
    const selectedCompany = computed(() => authStore.selectedCompany!)

    if (!selectedCompany.value) throw new Error('No selected company')

    return {
        company: selectedCompany,
    }
}
