import { ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from '~/config/constants'

export default defineNuxtPlugin(async (_nuxtApp) => {
    const authStore = useAuthStore()
    const accessToken = useCookie(ACCESS_TOKEN_COOKIE).value
    const refreshToken = useCookie(REFRESH_TOKEN_COOKIE).value

    try {
        if (accessToken) {
            await authStore.fetchAuthUser()
        } else if (refreshToken) {
            await authStore.refreshTokens()
            await authStore.fetchAuthUser()
        }
    } catch {
        await authStore.onLogout()
    }
})
