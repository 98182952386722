import revive_payload_client_4RuW3VThSk from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cGDUyxiz23 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_w080cDTJ1L from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_N29ID9RbDR from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.4_rollup@4.17.2_typescript@5.5.4_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_8eEzHjfBJr from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Gx8kVFOMg5 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.4_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_StszuHrW8f from "/opt/render/project/src/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_jjrq6xxso77kspjbcbwtfjd4uu/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_QiY79SFQiR from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Cm4hTmS0i9 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_Ug9vvEBllb from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_init_hVDiYuZhdY from "/opt/render/project/src/apps/app/plugins/1.init.ts";
import i18n_VfGcjrvSkj from "/opt/render/project/src/apps/app/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/opt/render/project/src/apps/app/plugins/sentry.ts";
import snackbar_G4WEaUIGl5 from "/opt/render/project/src/apps/app/plugins/snackbar.ts";
import zodErrors_YzNmqC0Kau from "/opt/render/project/src/apps/app/plugins/zodErrors.ts";
import plugin_auto_pageviews_client_834oXG4tlT from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.4_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_4RuW3VThSk,
  unhead_cGDUyxiz23,
  router_w080cDTJ1L,
  plugin_vue3_N29ID9RbDR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8eEzHjfBJr,
  plugin_client_Gx8kVFOMg5,
  plugin_StszuHrW8f,
  switch_locale_path_ssr_QiY79SFQiR,
  i18n_Cm4hTmS0i9,
  chunk_reload_client_Ug9vvEBllb,
  _1_init_hVDiYuZhdY,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE,
  snackbar_G4WEaUIGl5,
  zodErrors_YzNmqC0Kau,
  plugin_auto_pageviews_client_834oXG4tlT
]