import { default as _91_46_46_46all_937g9ZuorGchMeta } from "/opt/render/project/src/apps/app/pages/[...all].vue?macro=true";
import { default as _91id_93hkqpr4f80VMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/contacts/[id].vue?macro=true";
import { default as indexquzOQd3kZZMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/contacts/index.vue?macro=true";
import { default as newV2EekZLN7wMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/contacts/new.vue?macro=true";
import { default as _91_91folderId_93_93gmqs6zKOEzMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/drive/[[folderId]].vue?macro=true";
import { default as drivefevQjNSyr8Meta } from "/opt/render/project/src/apps/app/pages/[companySlug]/drive.vue?macro=true";
import { default as editey8j4tABK1Meta } from "/opt/render/project/src/apps/app/pages/[companySlug]/expenses/[id]/edit.vue?macro=true";
import { default as indexFRE6VziYRCMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/expenses/[id]/index.vue?macro=true";
import { default as indexRaI41tb1wcMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/expenses/index.vue?macro=true";
import { default as newtkOXlC0fjIMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/expenses/new.vue?macro=true";
import { default as index4UKnD1bsLPMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/index.vue?macro=true";
import { default as _91id_93scrdYqD3eBMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/items/[id].vue?macro=true";
import { default as indexzA88K25HaJMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/items/index.vue?macro=true";
import { default as newScVuhv5VVjMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/items/new.vue?macro=true";
import { default as editZPgDAJ2zGHMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/credit-notes/[id]/edit.vue?macro=true";
import { default as indexKYn7Mo3QZeMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/credit-notes/[id]/index.vue?macro=true";
import { default as indexj7wMuIGYurMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/credit-notes/index.vue?macro=true";
import { default as neww6XphfB1EvMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/credit-notes/new.vue?macro=true";
import { default as edith5eRhwJDEIMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/debit-notes/[id]/edit.vue?macro=true";
import { default as indexDaNhqU5tLWMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/debit-notes/[id]/index.vue?macro=true";
import { default as indexldAKmnJM0lMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/debit-notes/index.vue?macro=true";
import { default as newHq87chN0XfMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/debit-notes/new.vue?macro=true";
import { default as indexy1BwoOa2GPMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/index.vue?macro=true";
import { default as editBag7DegaqoMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/invoices/[id]/edit.vue?macro=true";
import { default as indexBhkhi5xoDvMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/invoices/[id]/index.vue?macro=true";
import { default as index1nr4sL2y4QMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/invoices/index.vue?macro=true";
import { default as new1rnn5nDSSJMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/invoices/new.vue?macro=true";
import { default as editiU5GSeWymwMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/proforma-invoices/[id]/edit.vue?macro=true";
import { default as indexK2ob1bYDo4Meta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/proforma-invoices/[id]/index.vue?macro=true";
import { default as indexzCWFPCRavzMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/proforma-invoices/index.vue?macro=true";
import { default as newCtGVOxZeilMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/revenues/proforma-invoices/new.vue?macro=true";
import { default as bank_45accountskb2NgQisZ0Meta } from "/opt/render/project/src/apps/app/pages/[companySlug]/settings/bank-accounts.vue?macro=true";
import { default as categoriesuajTr53EurMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/settings/categories.vue?macro=true";
import { default as general4Ok0ShIvV0Meta } from "/opt/render/project/src/apps/app/pages/[companySlug]/settings/general.vue?macro=true";
import { default as invoicesUe6b0ZNjyLMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/settings/invoices.vue?macro=true";
import { default as subscriptionhphCgZryRIMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/settings/subscription.vue?macro=true";
import { default as taxes3NqqtPp29nMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/settings/taxes.vue?macro=true";
import { default as usersdqo50z5DkGMeta } from "/opt/render/project/src/apps/app/pages/[companySlug]/settings/users.vue?macro=true";
import { default as settings6sna3R43a6Meta } from "/opt/render/project/src/apps/app/pages/[companySlug]/settings.vue?macro=true";
import { default as _91companySlug_93oKqnSHq5PIMeta } from "/opt/render/project/src/apps/app/pages/[companySlug].vue?macro=true";
import { default as accept_45invite7fa2ur1wmTMeta } from "/opt/render/project/src/apps/app/pages/accept-invite.vue?macro=true";
import { default as createC4b2pxags6Meta } from "/opt/render/project/src/apps/app/pages/companies/create.vue?macro=true";
import { default as indexDBgrb48zfEMeta } from "/opt/render/project/src/apps/app/pages/companies/index.vue?macro=true";
import { default as confirm_45emailDyEiXsbcJtMeta } from "/opt/render/project/src/apps/app/pages/confirm-email.vue?macro=true";
import { default as tasksjofqs7WJ4qMeta } from "/opt/render/project/src/apps/app/pages/dev/tasks.vue?macro=true";
import { default as indexuq4NErxqalMeta } from "/opt/render/project/src/apps/app/pages/forgot-password/index.vue?macro=true";
import { default as successHAKUFqtnJkMeta } from "/opt/render/project/src/apps/app/pages/forgot-password/success.vue?macro=true";
import { default as indexfNtJb6Phd5Meta } from "/opt/render/project/src/apps/app/pages/index.vue?macro=true";
import { default as logoutcIfJB36zyMMeta } from "/opt/render/project/src/apps/app/pages/logout.vue?macro=true";
import { default as register8YdTXs27IBMeta } from "/opt/render/project/src/apps/app/pages/register.vue?macro=true";
import { default as indexyZMuZo2q9rMeta } from "/opt/render/project/src/apps/app/pages/reset-password/index.vue?macro=true";
import { default as successIQh7DiPb7hMeta } from "/opt/render/project/src/apps/app/pages/reset-password/success.vue?macro=true";
import { default as profileiaZ7AVnz0bMeta } from "/opt/render/project/src/apps/app/pages/settings/profile.vue?macro=true";
import { default as securityL8IiR7eYsnMeta } from "/opt/render/project/src/apps/app/pages/settings/security.vue?macro=true";
import { default as settingsbaCzI7bB6GMeta } from "/opt/render/project/src/apps/app/pages/settings.vue?macro=true";
import { default as signinM9wgl8SWdJMeta } from "/opt/render/project/src/apps/app/pages/signin.vue?macro=true";
import { default as verify_45emailFLJTaW6DMFMeta } from "/opt/render/project/src/apps/app/pages/verify-email.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_937g9ZuorGchMeta?.name ?? "all",
    path: _91_46_46_46all_937g9ZuorGchMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_937g9ZuorGchMeta || {},
    alias: _91_46_46_46all_937g9ZuorGchMeta?.alias || [],
    redirect: _91_46_46_46all_937g9ZuorGchMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91companySlug_93oKqnSHq5PIMeta?.name ?? undefined,
    path: _91companySlug_93oKqnSHq5PIMeta?.path ?? "/:companySlug()",
    meta: _91companySlug_93oKqnSHq5PIMeta || {},
    alias: _91companySlug_93oKqnSHq5PIMeta?.alias || [],
    redirect: _91companySlug_93oKqnSHq5PIMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93hkqpr4f80VMeta?.name ?? "companySlug-contacts-id",
    path: _91id_93hkqpr4f80VMeta?.path ?? "contacts/:id()",
    meta: _91id_93hkqpr4f80VMeta || {},
    alias: _91id_93hkqpr4f80VMeta?.alias || [],
    redirect: _91id_93hkqpr4f80VMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/contacts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexquzOQd3kZZMeta?.name ?? "companySlug-contacts",
    path: indexquzOQd3kZZMeta?.path ?? "contacts",
    meta: indexquzOQd3kZZMeta || {},
    alias: indexquzOQd3kZZMeta?.alias || [],
    redirect: indexquzOQd3kZZMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: newV2EekZLN7wMeta?.name ?? "companySlug-contacts-new",
    path: newV2EekZLN7wMeta?.path ?? "contacts/new",
    meta: newV2EekZLN7wMeta || {},
    alias: newV2EekZLN7wMeta?.alias || [],
    redirect: newV2EekZLN7wMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/contacts/new.vue").then(m => m.default || m)
  },
  {
    name: drivefevQjNSyr8Meta?.name ?? "companySlug-drive",
    path: drivefevQjNSyr8Meta?.path ?? "drive",
    meta: drivefevQjNSyr8Meta || {},
    alias: drivefevQjNSyr8Meta?.alias || [],
    redirect: drivefevQjNSyr8Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/drive.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91folderId_93_93gmqs6zKOEzMeta?.name ?? "companySlug-drive-folderId",
    path: _91_91folderId_93_93gmqs6zKOEzMeta?.path ?? ":folderId?",
    meta: _91_91folderId_93_93gmqs6zKOEzMeta || {},
    alias: _91_91folderId_93_93gmqs6zKOEzMeta?.alias || [],
    redirect: _91_91folderId_93_93gmqs6zKOEzMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/drive/[[folderId]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: editey8j4tABK1Meta?.name ?? "companySlug-expenses-id-edit",
    path: editey8j4tABK1Meta?.path ?? "expenses/:id()/edit",
    meta: editey8j4tABK1Meta || {},
    alias: editey8j4tABK1Meta?.alias || [],
    redirect: editey8j4tABK1Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/expenses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexFRE6VziYRCMeta?.name ?? "companySlug-expenses-id",
    path: indexFRE6VziYRCMeta?.path ?? "expenses/:id()",
    meta: indexFRE6VziYRCMeta || {},
    alias: indexFRE6VziYRCMeta?.alias || [],
    redirect: indexFRE6VziYRCMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/expenses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaI41tb1wcMeta?.name ?? "companySlug-expenses",
    path: indexRaI41tb1wcMeta?.path ?? "expenses",
    meta: indexRaI41tb1wcMeta || {},
    alias: indexRaI41tb1wcMeta?.alias || [],
    redirect: indexRaI41tb1wcMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: newtkOXlC0fjIMeta?.name ?? "companySlug-expenses-new",
    path: newtkOXlC0fjIMeta?.path ?? "expenses/new",
    meta: newtkOXlC0fjIMeta || {},
    alias: newtkOXlC0fjIMeta?.alias || [],
    redirect: newtkOXlC0fjIMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/expenses/new.vue").then(m => m.default || m)
  },
  {
    name: index4UKnD1bsLPMeta?.name ?? "companySlug",
    path: index4UKnD1bsLPMeta?.path ?? "",
    meta: index4UKnD1bsLPMeta || {},
    alias: index4UKnD1bsLPMeta?.alias || [],
    redirect: index4UKnD1bsLPMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93scrdYqD3eBMeta?.name ?? "companySlug-items-id",
    path: _91id_93scrdYqD3eBMeta?.path ?? "items/:id()",
    meta: _91id_93scrdYqD3eBMeta || {},
    alias: _91id_93scrdYqD3eBMeta?.alias || [],
    redirect: _91id_93scrdYqD3eBMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/items/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzA88K25HaJMeta?.name ?? "companySlug-items",
    path: indexzA88K25HaJMeta?.path ?? "items",
    meta: indexzA88K25HaJMeta || {},
    alias: indexzA88K25HaJMeta?.alias || [],
    redirect: indexzA88K25HaJMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/items/index.vue").then(m => m.default || m)
  },
  {
    name: newScVuhv5VVjMeta?.name ?? "companySlug-items-new",
    path: newScVuhv5VVjMeta?.path ?? "items/new",
    meta: newScVuhv5VVjMeta || {},
    alias: newScVuhv5VVjMeta?.alias || [],
    redirect: newScVuhv5VVjMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/items/new.vue").then(m => m.default || m)
  },
  {
    name: editZPgDAJ2zGHMeta?.name ?? "companySlug-revenues-credit-notes-id-edit",
    path: editZPgDAJ2zGHMeta?.path ?? "revenues/credit-notes/:id()/edit",
    meta: editZPgDAJ2zGHMeta || {},
    alias: editZPgDAJ2zGHMeta?.alias || [],
    redirect: editZPgDAJ2zGHMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/credit-notes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexKYn7Mo3QZeMeta?.name ?? "companySlug-revenues-credit-notes-id",
    path: indexKYn7Mo3QZeMeta?.path ?? "revenues/credit-notes/:id()",
    meta: indexKYn7Mo3QZeMeta || {},
    alias: indexKYn7Mo3QZeMeta?.alias || [],
    redirect: indexKYn7Mo3QZeMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/credit-notes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexj7wMuIGYurMeta?.name ?? "companySlug-revenues-credit-notes",
    path: indexj7wMuIGYurMeta?.path ?? "revenues/credit-notes",
    meta: indexj7wMuIGYurMeta || {},
    alias: indexj7wMuIGYurMeta?.alias || [],
    redirect: indexj7wMuIGYurMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/credit-notes/index.vue").then(m => m.default || m)
  },
  {
    name: neww6XphfB1EvMeta?.name ?? "companySlug-revenues-credit-notes-new",
    path: neww6XphfB1EvMeta?.path ?? "revenues/credit-notes/new",
    meta: neww6XphfB1EvMeta || {},
    alias: neww6XphfB1EvMeta?.alias || [],
    redirect: neww6XphfB1EvMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/credit-notes/new.vue").then(m => m.default || m)
  },
  {
    name: edith5eRhwJDEIMeta?.name ?? "companySlug-revenues-debit-notes-id-edit",
    path: edith5eRhwJDEIMeta?.path ?? "revenues/debit-notes/:id()/edit",
    meta: edith5eRhwJDEIMeta || {},
    alias: edith5eRhwJDEIMeta?.alias || [],
    redirect: edith5eRhwJDEIMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/debit-notes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexDaNhqU5tLWMeta?.name ?? "companySlug-revenues-debit-notes-id",
    path: indexDaNhqU5tLWMeta?.path ?? "revenues/debit-notes/:id()",
    meta: indexDaNhqU5tLWMeta || {},
    alias: indexDaNhqU5tLWMeta?.alias || [],
    redirect: indexDaNhqU5tLWMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/debit-notes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexldAKmnJM0lMeta?.name ?? "companySlug-revenues-debit-notes",
    path: indexldAKmnJM0lMeta?.path ?? "revenues/debit-notes",
    meta: indexldAKmnJM0lMeta || {},
    alias: indexldAKmnJM0lMeta?.alias || [],
    redirect: indexldAKmnJM0lMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/debit-notes/index.vue").then(m => m.default || m)
  },
  {
    name: newHq87chN0XfMeta?.name ?? "companySlug-revenues-debit-notes-new",
    path: newHq87chN0XfMeta?.path ?? "revenues/debit-notes/new",
    meta: newHq87chN0XfMeta || {},
    alias: newHq87chN0XfMeta?.alias || [],
    redirect: newHq87chN0XfMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/debit-notes/new.vue").then(m => m.default || m)
  },
  {
    name: indexy1BwoOa2GPMeta?.name ?? "companySlug-revenues",
    path: indexy1BwoOa2GPMeta?.path ?? "revenues",
    meta: indexy1BwoOa2GPMeta || {},
    alias: indexy1BwoOa2GPMeta?.alias || [],
    redirect: indexy1BwoOa2GPMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/index.vue").then(m => m.default || m)
  },
  {
    name: editBag7DegaqoMeta?.name ?? "companySlug-revenues-invoices-id-edit",
    path: editBag7DegaqoMeta?.path ?? "revenues/invoices/:id()/edit",
    meta: editBag7DegaqoMeta || {},
    alias: editBag7DegaqoMeta?.alias || [],
    redirect: editBag7DegaqoMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/invoices/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexBhkhi5xoDvMeta?.name ?? "companySlug-revenues-invoices-id",
    path: indexBhkhi5xoDvMeta?.path ?? "revenues/invoices/:id()",
    meta: indexBhkhi5xoDvMeta || {},
    alias: indexBhkhi5xoDvMeta?.alias || [],
    redirect: indexBhkhi5xoDvMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1nr4sL2y4QMeta?.name ?? "companySlug-revenues-invoices",
    path: index1nr4sL2y4QMeta?.path ?? "revenues/invoices",
    meta: index1nr4sL2y4QMeta || {},
    alias: index1nr4sL2y4QMeta?.alias || [],
    redirect: index1nr4sL2y4QMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: new1rnn5nDSSJMeta?.name ?? "companySlug-revenues-invoices-new",
    path: new1rnn5nDSSJMeta?.path ?? "revenues/invoices/new",
    meta: new1rnn5nDSSJMeta || {},
    alias: new1rnn5nDSSJMeta?.alias || [],
    redirect: new1rnn5nDSSJMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/invoices/new.vue").then(m => m.default || m)
  },
  {
    name: editiU5GSeWymwMeta?.name ?? "companySlug-revenues-proforma-invoices-id-edit",
    path: editiU5GSeWymwMeta?.path ?? "revenues/proforma-invoices/:id()/edit",
    meta: editiU5GSeWymwMeta || {},
    alias: editiU5GSeWymwMeta?.alias || [],
    redirect: editiU5GSeWymwMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/proforma-invoices/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexK2ob1bYDo4Meta?.name ?? "companySlug-revenues-proforma-invoices-id",
    path: indexK2ob1bYDo4Meta?.path ?? "revenues/proforma-invoices/:id()",
    meta: indexK2ob1bYDo4Meta || {},
    alias: indexK2ob1bYDo4Meta?.alias || [],
    redirect: indexK2ob1bYDo4Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/proforma-invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzCWFPCRavzMeta?.name ?? "companySlug-revenues-proforma-invoices",
    path: indexzCWFPCRavzMeta?.path ?? "revenues/proforma-invoices",
    meta: indexzCWFPCRavzMeta || {},
    alias: indexzCWFPCRavzMeta?.alias || [],
    redirect: indexzCWFPCRavzMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/proforma-invoices/index.vue").then(m => m.default || m)
  },
  {
    name: newCtGVOxZeilMeta?.name ?? "companySlug-revenues-proforma-invoices-new",
    path: newCtGVOxZeilMeta?.path ?? "revenues/proforma-invoices/new",
    meta: newCtGVOxZeilMeta || {},
    alias: newCtGVOxZeilMeta?.alias || [],
    redirect: newCtGVOxZeilMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/revenues/proforma-invoices/new.vue").then(m => m.default || m)
  },
  {
    name: settings6sna3R43a6Meta?.name ?? "companySlug-settings",
    path: settings6sna3R43a6Meta?.path ?? "settings",
    meta: settings6sna3R43a6Meta || {},
    alias: settings6sna3R43a6Meta?.alias || [],
    redirect: settings6sna3R43a6Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: bank_45accountskb2NgQisZ0Meta?.name ?? "companySlug-settings-bank-accounts",
    path: bank_45accountskb2NgQisZ0Meta?.path ?? "bank-accounts",
    meta: bank_45accountskb2NgQisZ0Meta || {},
    alias: bank_45accountskb2NgQisZ0Meta?.alias || [],
    redirect: bank_45accountskb2NgQisZ0Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/settings/bank-accounts.vue").then(m => m.default || m)
  },
  {
    name: categoriesuajTr53EurMeta?.name ?? "companySlug-settings-categories",
    path: categoriesuajTr53EurMeta?.path ?? "categories",
    meta: categoriesuajTr53EurMeta || {},
    alias: categoriesuajTr53EurMeta?.alias || [],
    redirect: categoriesuajTr53EurMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/settings/categories.vue").then(m => m.default || m)
  },
  {
    name: general4Ok0ShIvV0Meta?.name ?? "companySlug-settings-general",
    path: general4Ok0ShIvV0Meta?.path ?? "general",
    meta: general4Ok0ShIvV0Meta || {},
    alias: general4Ok0ShIvV0Meta?.alias || [],
    redirect: general4Ok0ShIvV0Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/settings/general.vue").then(m => m.default || m)
  },
  {
    name: invoicesUe6b0ZNjyLMeta?.name ?? "companySlug-settings-invoices",
    path: invoicesUe6b0ZNjyLMeta?.path ?? "invoices",
    meta: invoicesUe6b0ZNjyLMeta || {},
    alias: invoicesUe6b0ZNjyLMeta?.alias || [],
    redirect: invoicesUe6b0ZNjyLMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/settings/invoices.vue").then(m => m.default || m)
  },
  {
    name: subscriptionhphCgZryRIMeta?.name ?? "companySlug-settings-subscription",
    path: subscriptionhphCgZryRIMeta?.path ?? "subscription",
    meta: subscriptionhphCgZryRIMeta || {},
    alias: subscriptionhphCgZryRIMeta?.alias || [],
    redirect: subscriptionhphCgZryRIMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/settings/subscription.vue").then(m => m.default || m)
  },
  {
    name: taxes3NqqtPp29nMeta?.name ?? "companySlug-settings-taxes",
    path: taxes3NqqtPp29nMeta?.path ?? "taxes",
    meta: taxes3NqqtPp29nMeta || {},
    alias: taxes3NqqtPp29nMeta?.alias || [],
    redirect: taxes3NqqtPp29nMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/settings/taxes.vue").then(m => m.default || m)
  },
  {
    name: usersdqo50z5DkGMeta?.name ?? "companySlug-settings-users",
    path: usersdqo50z5DkGMeta?.path ?? "users",
    meta: usersdqo50z5DkGMeta || {},
    alias: usersdqo50z5DkGMeta?.alias || [],
    redirect: usersdqo50z5DkGMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/[companySlug]/settings/users.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: accept_45invite7fa2ur1wmTMeta?.name ?? "accept-invite",
    path: accept_45invite7fa2ur1wmTMeta?.path ?? "/accept-invite",
    meta: accept_45invite7fa2ur1wmTMeta || {},
    alias: accept_45invite7fa2ur1wmTMeta?.alias || [],
    redirect: accept_45invite7fa2ur1wmTMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/accept-invite.vue").then(m => m.default || m)
  },
  {
    name: createC4b2pxags6Meta?.name ?? "companies-create",
    path: createC4b2pxags6Meta?.path ?? "/companies/create",
    meta: createC4b2pxags6Meta || {},
    alias: createC4b2pxags6Meta?.alias || [],
    redirect: createC4b2pxags6Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/companies/create.vue").then(m => m.default || m)
  },
  {
    name: indexDBgrb48zfEMeta?.name ?? "companies",
    path: indexDBgrb48zfEMeta?.path ?? "/companies",
    meta: indexDBgrb48zfEMeta || {},
    alias: indexDBgrb48zfEMeta?.alias || [],
    redirect: indexDBgrb48zfEMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: confirm_45emailDyEiXsbcJtMeta?.name ?? "confirm-email",
    path: confirm_45emailDyEiXsbcJtMeta?.path ?? "/confirm-email",
    meta: confirm_45emailDyEiXsbcJtMeta || {},
    alias: confirm_45emailDyEiXsbcJtMeta?.alias || [],
    redirect: confirm_45emailDyEiXsbcJtMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/confirm-email.vue").then(m => m.default || m)
  },
  {
    name: tasksjofqs7WJ4qMeta?.name ?? "dev-tasks",
    path: tasksjofqs7WJ4qMeta?.path ?? "/dev/tasks",
    meta: tasksjofqs7WJ4qMeta || {},
    alias: tasksjofqs7WJ4qMeta?.alias || [],
    redirect: tasksjofqs7WJ4qMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/dev/tasks.vue").then(m => m.default || m)
  },
  {
    name: indexuq4NErxqalMeta?.name ?? "forgot-password",
    path: indexuq4NErxqalMeta?.path ?? "/forgot-password",
    meta: indexuq4NErxqalMeta || {},
    alias: indexuq4NErxqalMeta?.alias || [],
    redirect: indexuq4NErxqalMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: successHAKUFqtnJkMeta?.name ?? "forgot-password-success",
    path: successHAKUFqtnJkMeta?.path ?? "/forgot-password/success",
    meta: successHAKUFqtnJkMeta || {},
    alias: successHAKUFqtnJkMeta?.alias || [],
    redirect: successHAKUFqtnJkMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/forgot-password/success.vue").then(m => m.default || m)
  },
  {
    name: indexfNtJb6Phd5Meta?.name ?? "index",
    path: indexfNtJb6Phd5Meta?.path ?? "/",
    meta: indexfNtJb6Phd5Meta || {},
    alias: indexfNtJb6Phd5Meta?.alias || [],
    redirect: indexfNtJb6Phd5Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logoutcIfJB36zyMMeta?.name ?? "logout",
    path: logoutcIfJB36zyMMeta?.path ?? "/logout",
    meta: logoutcIfJB36zyMMeta || {},
    alias: logoutcIfJB36zyMMeta?.alias || [],
    redirect: logoutcIfJB36zyMMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: register8YdTXs27IBMeta?.name ?? "register",
    path: register8YdTXs27IBMeta?.path ?? "/register",
    meta: register8YdTXs27IBMeta || {},
    alias: register8YdTXs27IBMeta?.alias || [],
    redirect: register8YdTXs27IBMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexyZMuZo2q9rMeta?.name ?? "reset-password",
    path: indexyZMuZo2q9rMeta?.path ?? "/reset-password",
    meta: indexyZMuZo2q9rMeta || {},
    alias: indexyZMuZo2q9rMeta?.alias || [],
    redirect: indexyZMuZo2q9rMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: successIQh7DiPb7hMeta?.name ?? "reset-password-success",
    path: successIQh7DiPb7hMeta?.path ?? "/reset-password/success",
    meta: successIQh7DiPb7hMeta || {},
    alias: successIQh7DiPb7hMeta?.alias || [],
    redirect: successIQh7DiPb7hMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/reset-password/success.vue").then(m => m.default || m)
  },
  {
    name: settingsbaCzI7bB6GMeta?.name ?? "settings",
    path: settingsbaCzI7bB6GMeta?.path ?? "/settings",
    meta: settingsbaCzI7bB6GMeta || {},
    alias: settingsbaCzI7bB6GMeta?.alias || [],
    redirect: settingsbaCzI7bB6GMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: profileiaZ7AVnz0bMeta?.name ?? "settings-profile",
    path: profileiaZ7AVnz0bMeta?.path ?? "profile",
    meta: profileiaZ7AVnz0bMeta || {},
    alias: profileiaZ7AVnz0bMeta?.alias || [],
    redirect: profileiaZ7AVnz0bMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: securityL8IiR7eYsnMeta?.name ?? "settings-security",
    path: securityL8IiR7eYsnMeta?.path ?? "security",
    meta: securityL8IiR7eYsnMeta || {},
    alias: securityL8IiR7eYsnMeta?.alias || [],
    redirect: securityL8IiR7eYsnMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/settings/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: signinM9wgl8SWdJMeta?.name ?? "signin",
    path: signinM9wgl8SWdJMeta?.path ?? "/signin",
    meta: signinM9wgl8SWdJMeta || {},
    alias: signinM9wgl8SWdJMeta?.alias || [],
    redirect: signinM9wgl8SWdJMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailFLJTaW6DMFMeta?.name ?? "verify-email",
    path: verify_45emailFLJTaW6DMFMeta?.path ?? "/verify-email",
    meta: verify_45emailFLJTaW6DMFMeta || {},
    alias: verify_45emailFLJTaW6DMFMeta?.alias || [],
    redirect: verify_45emailFLJTaW6DMFMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/app/pages/verify-email.vue").then(m => m.default || m)
  }
]