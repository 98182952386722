import type {
    UserResponse,
    CreateUserRequest,
    CreateUserByInviteRequest,
    LoginRequest,
    TokensResponse,
    CompanyResponse,
} from '~/schemas'
import { ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from '~/config/constants'

const accessTokenCookie = useCookie(ACCESS_TOKEN_COOKIE, {
    path: '/',
    maxAge: 60 * 60 * 24, // 1 day
    sameSite: 'lax',
})

const refreshTokenCookie = useCookie(REFRESH_TOKEN_COOKIE, {
    path: '/',
    maxAge: 60 * 60 * 24 * 365, // 1 year
    sameSite: 'lax',
})

async function setTokenCookies({ accessToken, refreshToken }: TokensResponse) {
    accessTokenCookie.value = accessToken
    refreshTokenCookie.value = refreshToken
    await nextTick()
}

async function removeTokenCookies() {
    accessTokenCookie.value = null
    refreshTokenCookie.value = null
    await nextTick()
}

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null as UserResponse | null,
    }),

    getters: {
        isLoggedIn: (state) => {
            return !!state.user
        },
        fullName: (state) => {
            return state.user
                ? `${state.user.firstName} ${state.user.lastName}`
                : ''
        },
        selectedCompany: (state): CompanyResponse | null => {
            return state.user?.companies.find((c) => c.selected) ?? null
        },
        hasCompanies: (state): boolean => {
            return (
                !!state.user?.companies.length ||
                !!state.user?.invitations.length
            )
        },
    },

    actions: {
        async login(loginRequest: LoginRequest) {
            const tokens = await $api.auth.login(loginRequest)
            await this.onLogin(tokens)
        },

        async register(createUserRequest: CreateUserRequest) {
            const tokens = await $api.auth.register(createUserRequest)
            await this.onLogin(tokens)
        },

        async registerByInvite(createUserRequest: CreateUserByInviteRequest) {
            const tokens = await $api.auth.registerByInvite(createUserRequest)
            await this.onLogin(tokens)
        },

        async refreshTokens() {
            if (!refreshTokenCookie.value) {
                throw new Error('No refresh token')
            }

            const tokens = await $api.auth.refreshTokens({
                refreshToken: refreshTokenCookie.value,
            })

            await setTokenCookies(tokens)
        },

        async fetchAuthUser() {
            this.user = await $api.me.getProfile()
        },

        async onLogin(tokens: TokensResponse) {
            await setTokenCookies(tokens)
            await this.fetchAuthUser()
        },

        async onLogout(replace = false) {
            this.$reset()
            await removeTokenCookies()

            return navigateTo('/signin', { replace })
        },
    },
})
